import { useMemo, useState } from 'react';
import {
  Heading,
  IconArrowCollapse,
  Image,
  PropsWithClassProps,
  Button,
  DropdownItem,
} from '@vgn-medien-holding/vgn-fe-components';
import { nanoid } from 'nanoid';
import { twMerge } from 'tailwind-merge';
import { useIsClient } from 'usehooks-ts';
import { Button as TvmButton } from '@components/atoms/Button/Button';
import { Dropdown } from '@components/atoms/Dropdown/Dropdown';
import { SubscriptionOverviewOffer } from '@src/lib/graphql/generated';
import { AboStickerOverview } from '../AboSticker/AboStickerOverview';

export interface AboSelectionProps extends PropsWithClassProps<'info' | 'tagline' | 'title' | 'favorite'> {
  abo: SubscriptionOverviewOffer;
  onClick?: () => void;
  active?: boolean;
  small?: boolean;
}

export const AboSelection = ({ abo, small, active, onClick, classProps }: AboSelectionProps) => {
  const [selectedOption, setSelectedOption] = useState<Partial<DropdownItem>>(null);
  const isClient = useIsClient();
  const { offer, tagline, title } = abo ?? {};

  const multipleOptions = useMemo(
    () =>
      offer?.multiple_options.map((e, index) => ({
        ...e,
        id: isClient ? nanoid() : `${index}`,
      })),
    [isClient, offer?.multiple_options],
  );

  if (!abo) return null;

  const selectedOptionItem = multipleOptions.find((e) => e.id === selectedOption?.id);

  return (
    <Button
      key={abo?.id}
      omit={!small}
      hasAction={true}
      classProps={{
        root: twMerge(
          'card-gradient w-full rounded-2xl border border-gray-650/65 p-6 text-start text-white shadow-card-base transition-all duration-200 *:transition-all *:duration-200 xl:p-4',
          active && small && 'xl:border-primary',
          active && 'card-gradient',
          small && !active && 'hover:border-primary xl:bg-gray-820/70',
          classProps?.root,
        ),
      }}
      onAction={onClick}
    >
      {abo?.favorite && (
        <div
          className={twMerge(
            'mb-2 inline-block w-auto rounded bg-primary p-1 text-label-sm uppercase',
            classProps?.favorite,
          )}
        >
          Am Beliebtesten
        </div>
      )}
      <div className="flex items-center justify-between gap-4">
        <Heading
          level={3}
          classProps={{ root: twMerge('text-heading-4 font-herokid', classProps?.title) }}
          title={title || offer?.overview_title}
        />
        <IconArrowCollapse classProps={{ root: twMerge('rotate-0 xl:hidden', small && active && 'rotate-180') }} />
      </div>

      <div
        className={twMerge(
          'mr-9 mt-2 hidden text-sm text-gray-400',
          small && 'block',
          small && active && 'hidden xl:block',
          classProps?.tagline,
        )}
      >
        {abo?.tagline || offer?.overview_tagline}
      </div>
      <div className={twMerge('mt-6 hidden text-gray-400', active && 'block', small && 'xl:hidden', classProps?.info)}>
        {(selectedOptionItem ? selectedOptionItem?.option_image : !!offer?.overview_image?.url) && (
          <div className="relative mb-3 h-60">
            <Image
              alt={selectedOptionItem ? selectedOptionItem.option_image?.alt : offer?.overview_image?.alt}
              copyright={
                selectedOptionItem
                  ? selectedOptionItem.option_image?.copyright_text
                  : offer?.overview_image?.copyright_text
              }
              src={selectedOptionItem ? selectedOptionItem.option_image?.url : offer?.overview_image?.url}
              fill
              sizes="(min-width: 1280) 33vw, 50vw"
              priority
            />
            {!offer?.hide_breaker && (
              <AboStickerOverview
                price={selectedOptionItem ? selectedOptionItem?.option_price_after : offer?.overview_price_after}
                text={offer?.breaker_text}
              />
            )}
          </div>
        )}
        <div className="space-y-3">
          <div>
            {(selectedOptionItem ? selectedOptionItem?.option_price_previous : offer?.overview_price_previous) && (
              <div className="text-base line-through lg:text-xl">
                &euro; {selectedOptionItem ? selectedOptionItem?.option_price_previous : offer?.overview_price_previous}
              </div>
            )}
            <div
              className={twMerge(
                'flex items-center gap-3 text-white',
                (!offer.overview_price_textline_1 || !offer.overview_price_textline_2) && 'items-baseline',
              )}
            >
              <div className="whitespace-nowrap text-4xl font-bold lg:text-[2.5rem]">
                &euro; {selectedOptionItem ? selectedOptionItem?.option_price_after : offer?.overview_price_after}
              </div>
              <div className="text-sm lg:text-base">
                <div className="font-bold">
                  {selectedOptionItem ? selectedOptionItem?.option_price_textline_1 : offer.overview_price_textline_1}
                </div>
                {selectedOptionItem ? selectedOptionItem?.option_price_textline_2 : offer.overview_price_textline_2}
              </div>
            </div>
          </div>
          <div className="text-lg font-semibold lg:text-xl lg:font-bold">{tagline ?? offer?.overview_tagline}</div>
          <div>{selectedOptionItem ? selectedOptionItem?.option_subheadline : offer?.overview_subheadline}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: selectedOptionItem ? selectedOptionItem?.option_benefits : offer?.overview_benefits,
            }}
            className="checkmark-list space-y-2.5"
          />
        </div>
        {isClient && offer?.use_multiple_options && multipleOptions?.length > 0 && (
          <div
            onClick={(evt) => {
              evt.stopPropagation();
            }}
          >
            <Dropdown
              items={multipleOptions?.map((e) => ({ id: e.id + '', label: e.option_title })) ?? []}
              classProps={{ root: 'max-w-screen-xs w-full pt-6 pb-3' }}
              selectedItem={selectedOption}
              setSelectedItem={(item) => {
                setSelectedOption(item);
              }}
              placeholder="Gutschein auswählen"
            />
          </div>
        )}
        <TvmButton
          title="Jetzt Kaufen"
          hasAction={false}
          disabled={offer?.use_multiple_options && !selectedOption?.id}
          link={selectedOptionItem ? selectedOptionItem?.option_buy_link : (offer?.overview_buy_link ?? '/')}
          buttonStyle="primary"
          classProps={{
            root: twMerge(
              'w-full justify-center my-10 !py-3 !px-10',
              (!offer?.use_multiple_options || (offer.use_multiple_options && selectedOption?.id)) &&
                '!bg-primary hover:!bg-transparent',
            ),
          }}
        />
        {(selectedOptionItem ? selectedOptionItem?.option_disclaimer : !!offer?.top_disclaimer) && (
          <div className="text-2xs leading-body prose-a:text-white prose-a:underline">
            Disclaimer:{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: selectedOptionItem ? selectedOptionItem?.option_disclaimer : offer?.top_disclaimer,
              }}
            />
          </div>
        )}
      </div>
    </Button>
  );
};
