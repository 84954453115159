import React from 'react';
import {
  Dropdown as DefaultDropdown,
  DropdownProps as DefaultDropdownProps,
  composeStyles,
} from '@vgn-medien-holding/vgn-fe-components';
import { IconArrowCollapse } from '@src/assets/icon-arrow-collapse';
import borderedStyle from './styles/bordered';
import filledStyle from './styles/filled';

export interface DropdownProps extends DefaultDropdownProps {
  dropdownStyle?: 'bordered' | 'filled';
}

export const Dropdown = ({
  classProps,
  items,
  placeholder,
  selectedItem,
  setSelectedItem,
  openOnHover,
  buttonAfterSlot,
  CustomListItem,
  CustomButton,
  dropdownStyle = 'filled',
}: DropdownProps) => {
  const styles = composeStyles(dropdownStyle === 'bordered' ? borderedStyle : filledStyle, classProps);
  return (
    <DefaultDropdown
      classProps={styles}
      items={items}
      placeholder={placeholder}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      openOnHover={openOnHover}
      buttonAfterSlot={buttonAfterSlot ?? <IconArrowCollapse classProps={{ root: 'text-white size-7' }} />}
      CustomListItem={CustomListItem}
      CustomButton={CustomButton}
    />
  );
};
